export function dataLayerPush(...args) {
  if (typeof window !== "undefined" && window.dataLayer != null) {
    window.dataLayer.push(...args);
  }
}

export function fbq(...args) {
  if (typeof window !== "undefined" && window.fbq != null) {
    window.fbq(...args);
  }
}

const browser = detectBrowser();

if (typeof window !== "undefined") {
  document.documentElement.setAttribute("data-browser", browser);
}

export default browser;

function detectBrowser() {
  if (typeof window === "undefined") {
    return "unknown";
  }

  if (window.chrome) {
    return "chrome";
  }

  if (window.sidebar) {
    return "firefox";
  }

  if (window.MSInputMethodContext && document.documentMode) {
    return "ie";
  }

  if (window.MSInputMethodContext && !document.documentMode) {
    return "edge";
  }

  if (/Apple/.test(window.navigator.vendor)) {
    return "safari";
  }

  return "unknown";
}

/* global require */

import "../main/browser";
import "../main/css/global.scss";
import "../main/css/nprogress.scss";

import { djedi } from "djedi-react";
import unfetch from "isomorphic-unfetch";
import App, { Container } from "next/app";
import Head from "next/head";
import Router from "next/router";
import NProgress from "nprogress";
import React from "react";

import { API_URL, patchResponse } from "../api/request";
import favicon from "../images/favicon.png";
import * as events from "../main/events";
import { captureException } from "../sentry";
import { dataLayerPush, fbq } from "../utils/tracking";
import ErrorComponent from "./_error";

Router.events.on("routeChangeStart", () => {
  NProgress.start();
});

Router.events.on("routeChangeComplete", url => {
  NProgress.done();
  dataLayerPush({ event: "pageview", pageview: url });
});

Router.events.on("routeChangeError", () => {
  NProgress.done();
});

if (typeof window !== "undefined") {
  // Polyfill `Element.prototype.closest` for IE11.
  require("element-closest/browser");

  require("focus-visible");

  events.chat.on("navigate", data => {
    window.location = data.url;
  });

  events.chat.on("start", () => {
    dataLayerPush({ event: "chatStart" });
  });

  events.chat.on("complete", () => {
    dataLayerPush({ event: "chatComplete" });
  });

  events.chat.on("contact_me", () => {
    dataLayerPush({ event: "chatContactMe" });
  });

  events.chat.on("nodes", nodes => {
    Object.keys(nodes).forEach(uri => {
      djedi.reportRenderedNode({ uri });
    });
    window.djedi = djedi;
  });

  events.contactMe.on("complete", () => {
    dataLayerPush({ event: "contactFormSubmit" });
  });

  events.landing.on("CTA", data => {
    dataLayerPush({ event: "landingCTA" });
    fbq("trackCustom", "landingCTA", { type: data.type });
  });
}

djedi.options.baseUrl = `${API_URL}/cms/api`;

// This should ideally have been set before going into production, but I
// forgot. Now all Swedish text is under the "en-us" namespace. Oh well.
// djedi.options.languages.default = "sv-se";

djedi.options.fetch = unfetch;

djedi.options.defaultRender = state => {
  switch (state.type) {
    case "loading":
      return "Laddar…";
    case "error":
      return `Innehållet gick inte att hämta 😞 (${state.error.status})`;
    case "success":
      return state.content;
    default:
      return null;
  }
};

patchResponse();
const djediInjectAdminPromise = djedi.injectAdmin();

export default class MyApp extends App {
  static async getInitialProps({ Component, ctx: passedCtx }) {
    const ctx = enhanceCtx(passedCtx);

    // Do as little as possible when rendering the error page, in case something
    // in this `getInitialProps` function fails. We don't want to error on the
    // error page, so to speak.
    if (Component === ErrorComponent) {
      return { pageProps: await Component.getInitialProps(ctx) };
    }

    const [pageProps] = await Promise.all([
      Component.getInitialProps != null ? Component.getInitialProps(ctx) : {},
      Component.skipDjediPrefetch ? undefined : djedi.prefetch(),
    ]).catch(error => {
      captureException(error, ctx);
      throw error;
    });

    const nodes = djedi.track();

    return { pageProps, nodes };
  }

  constructor(props) {
    super(props);
    const { nodes } = props;
    if (nodes != null) {
      djedi.addNodes(nodes);
    }
  }

  componentDidCatch(error, errorInfo) {
    captureException(error, { errorInfo });
    super.componentDidCatch(error, errorInfo);
  }

  render() {
    const { Component, pageProps } = this.props;

    return (
      <Container>
        <Head>
          {/* This cannot be placed in _document.js, because that file is only
          compiled for the server build and no static files are emitted there. */}
          <link rel="icon" href={favicon} />
        </Head>
        <Component
          {...pageProps}
          djediInjectAdminPromise={djediInjectAdminPromise}
        />
      </Container>
    );
  }
}

/*
This function enhances the standard Next.js `ctx` (passed to `getInitialProps`)
object in a couple of ways.

By default, the value of given key in `ctx.query` is a string if a the query
parameter was given _once,_ but if it was given multiple times (`?foo=1&foo=2`)
the value is an array of all of those values. Most of the time one wants only a
single value. This function normalizes `ctx.query` so the values are always
strings (by favoring the _last_ given query parameter), and adds `ctx.queryList`
(where the values always are arrays) if you need all the given values.
*/
function enhanceCtx(ctx) {
  const { query, queryList } = Object.entries(ctx.query).reduce(
    (result, [key, value]) => {
      result.query[key] = Array.isArray(value)
        ? value[value.length - 1]
        : value;
      result.queryList[key] = [].concat(value);
      return result;
    },
    { query: {}, queryList: {} },
  );

  return {
    ...ctx,
    query,
    queryList,
  };
}

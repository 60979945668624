import PropTypes from "prop-types";
import React from "react";

import Layout from "../components/Layout";
import styles from "./_error.scss";

const TITLES = {
  404: "Sidan kunde inte hittas",
  500: "Internt serverfel",
};

const FALLBACK_TITLE = "Ett oväntat fel inträffade";

export default class Error extends React.Component {
  static propTypes = {
    statusCode: PropTypes.number,
  };

  static defaultProps = {
    statusCode: undefined,
  };

  static getInitialProps({ res, err, query }) {
    const statusCode =
      query.status != null
        ? Number(query.status)
        : err != null && typeof err.status === "number"
        ? err.status
        : err != null &&
          err.response != null &&
          typeof err.response.status === "number"
        ? err.response.status
        : res
        ? res.statusCode
        : undefined;

    if (res != null && typeof res.status === "function") {
      // On the server, set the status code.
      res.status(statusCode);
    }

    return { statusCode };
  }

  render() {
    const { statusCode } = this.props;

    const title = TITLES[statusCode] || FALLBACK_TITLE;

    return (
      <Layout transparentNav={false} title={title} noindex>
        <div className={styles.root}>
          <p>
            {statusCode == null || Number.isNaN(statusCode)
              ? title
              : `${statusCode} – ${title}`}
          </p>
        </div>
      </Layout>
    );
  }
}
